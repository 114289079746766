var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Admin: CMS" } }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Frontend")]),
      _vm.loading
        ? _c("ck-loader")
        : [
            _vm.settings.$errors.any()
              ? _c(
                  "gov-error-summary",
                  { attrs: { title: "Check for errors" } },
                  [
                    _c(
                      "gov-list",
                      _vm._l(_vm.settings.$errors.all(), function(
                        error,
                        field
                      ) {
                        return _c("li", {
                          key: field,
                          domProps: { textContent: _vm._s(error[0]) }
                        })
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "gov-tabs",
              { attrs: { tabs: _vm.tabs } },
              [
                _c("router-view", {
                  attrs: { errors: _vm.settings.$errors },
                  on: {
                    clear: function($event) {
                      return _vm.settings.$errors.clear("cms." + $event)
                    }
                  },
                  model: {
                    value: _vm.settings.cms.frontend,
                    callback: function($$v) {
                      _vm.$set(_vm.settings.cms, "frontend", $$v)
                    },
                    expression: "settings.cms.frontend"
                  }
                })
              ],
              1
            ),
            _vm.settings.$submitting
              ? _c("gov-button", { attrs: { disabled: "", type: "submit" } }, [
                  _vm._v("Updating...")
                ])
              : _c(
                  "gov-button",
                  { attrs: { type: "submit" }, on: { click: _vm.onSubmit } },
                  [_vm._v("Update")]
                ),
            _vm.settings.$errors.any() ? _c("ck-submit-error") : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }